import useIsServer from "@/Hooks/useIsServer";
import { router } from "@inertiajs/react";
import PlanBg from "@resources/main-estate-b2.jpg?format=webp&quality=75&&imagetools";

const MainApartmentViewEstateB = () => {
    const isServer = useIsServer();

    const highlightFloor = (floor: string) => {
        if (!isServer) {
            const rect = document.getElementById(floor);

            if (rect) {
                // @ts-ignore
                rect.style.fill = rect.getAttribute("fill")?.replace('transparent', '#00405350')
            }
        }
    }

    const unHighlightFloor = (floor: string) => {
        if (!isServer) {
            const rect = document.getElementById(floor);

            if (rect) {
                // @ts-ignore
                rect.style.fill = rect.getAttribute("fill")?.replace('#00405350', 'transparent')
            }
        }
    }

    const floorClick = (floor: number) => {
        router.visit(route('frontend.apartments.plan.B', { floor: floor }))
    }

    return (
        <div className="w-full">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2000 988">
                <g>
                    <g id="Warstwa_1">
                        <g id="Warstwa_1-2" data-name="Warstwa_1">
                            <image width="2000" height="988" xlinkHref={PlanBg} />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F01')} onMouseLeave={() => unHighlightFloor('F01')} onClick={() => floorClick(1)} id="F01" points="332.9 834.8 332.9 769 1186.6 769 1257.1 764.8 1655.1 764.8 1655.1 842.3 1257.1 842.3 1186.6 838.1 332.9 834.8" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F02')} onMouseLeave={() => unHighlightFloor('F02')} onClick={() => floorClick(2)} id="F02" points="332.9 768.2 332.9 700.2 1186.6 704.6 1257.1 693.9 1655.1 692.5 1655.1 764.8 1257.1 764.6 1186.6 769.4 332.9 768.2" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F03')} onMouseLeave={() => unHighlightFloor('F03')} onClick={() => floorClick(3)} id="F03" points="1403.6 618.8 1403.6 625.6 1257.1 629.6 1186.6 644.3 332.9 644 332.9 700.2 1186.6 704.6 1257.1 693.9 1655.1 692.5 1655.1 618.8 1403.6 618.8" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F04')} onMouseLeave={() => unHighlightFloor('F04')} onClick={() => floorClick(4)} id="F04" points="1403.6 542.4 1403.6 549.2 1257.1 561.2 1186.6 582.9 332.9 584.6 332.9 644 1186.6 644.3 1257.1 629.6 1403.6 625.6 1403.6 618.8 1655.1 618.8 1655.1 542.4 1403.6 542.4" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F05')} onMouseLeave={() => unHighlightFloor('F05')} onClick={() => floorClick(5)} id="F05" points="1403.6 471.4 1403.6 478.1 1257.1 491.1 1186.6 520.3 332.9 522.3 332.9 583.9 627.7 584 1186.6 582.9 1257.1 561.2 1403.6 549.2 1403.6 542.4 1655.1 542.4 1655.1 471.4 1403.6 471.4" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F06')} onMouseLeave={() => unHighlightFloor('F06')} onClick={() => floorClick(6)} id="F06" points="1403.6 397.9 1403.6 404.7 1257.1 422.5 1186.6 456.4 332.9 460.9 332.9 522.3 1186.6 520.3 1257.1 491.1 1403.6 478.1 1403.6 471.4 1655.1 471.4 1655.1 397.9 1403.6 397.9" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F07')} onMouseLeave={() => unHighlightFloor('F07')} onClick={() => floorClick(7)} id="F07" points="1403.6 324.5 1403.6 331.2 1257.1 353.8 1186.6 391.8 332.9 396.3 332.9 460.9 628.6 459.3 1042.8 457.1 1186.6 456.4 1257.1 422.5 1403.6 404.7 1403.6 397.9 1655.1 397.9 1655.1 324.5 1403.6 324.5" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F08')} onMouseLeave={() => unHighlightFloor('F08')} onClick={() => floorClick(8)} id="F08" points="1403.6 252.5 1403.6 259.2 1257.1 284.8 1186.6 332.8 332.9 341.3 332.9 396.3 1186.6 391.8 1257.1 353.8 1403.6 331.2 1403.6 324.5 1655.1 324.5 1655.1 252.5 1403.6 252.5" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F09')} onMouseLeave={() => unHighlightFloor('F09')} onClick={() => floorClick(9)} id="F09" points="1403.6 180.1 1403.6 186.9 1257.1 217.5 1186.6 270.5 332.9 279 332.9 341.3 1186.6 332.8 1257.1 284.8 1403.6 259.2 1403.6 252.5 1655.1 252.5 1655.1 178.1 1403.6 180.1" />
                            <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F10')} onMouseLeave={() => unHighlightFloor('F10')} onClick={() => floorClick(10)} id="F10" points="1392.9 87.3 1354.5 127.2 1354.5 134.5 1257.1 135.5 1227.6 172.4 1227.6 181.4 1207.5 181.8 1201.2 188 1193.8 188 1186.6 196.9 1134.1 197.7 1129.8 203.2 897.5 205.3 897.8 202.9 700.6 206 699.7 208.1 538 209.3 537.8 198.8 332.9 200.3 332.9 279 1186.6 270.5 1257.1 217.5 1403.6 186.9 1403.6 180.1 1655.1 178.1 1655.1 84.5 1392.9 87.3" />
                        </g>
                        <polygon fill="transparent" className="cursor-pointer transition-all ease-in-out duration-300" onMouseEnter={() => highlightFloor('F0')} onMouseLeave={() => unHighlightFloor('F0')} onClick={() => floorClick(0)} id="F0" points="1655.1 842.3 1655.1 923.7 1378.8 923.7 1125.3 896.4 332.9 896.4 332.9 834.8 1184 838.1 1257.1 842.3 1655.1 842.3" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default MainApartmentViewEstateB;